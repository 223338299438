import { Component, OnInit, OnDestroy, ViewChild, ElementRef, inject } from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";
import { last, map } from "lodash";
import moment from "moment";

import { UsersService } from "../account-management/users/users.service";
import { Tag, Source, KeyMap } from "../../models/shared";

import { ScteService } from "./scte.service";
import { SCTELog, SCTEFilter } from "./scte";
import { LogDetailModalService } from "./log-detail-modal.service";
import { ResizeService } from "src/app/services/resize.service";
import { TranslateService } from "@ngx-translate/core";
import { TitleService } from "../../services/title.service";
import { TableSchema } from "src/app/components/shared/table-list/table-list.component";
import { ZxTableActionsComponent } from "src/app/components/shared/zx-table-actions/zx-table-actions.component";
import { assignGenericLinkTextInputsFactory } from "src/app/components/shared/zx-table-actions/zx-table-actions.table-adapter";
import { UntypedFormControl } from "@angular/forms";
import { Constants } from "src/app/constants/constants";
import { scte35ColorsService } from "../../helpers/scte-35-colors.service";
import { assignNgbHighlightInputsFactory } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.table-adapter";
import { ZxNgbHighlightComponent } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.component";
import { ZxDateTimeDisplayComponent } from "src/app/components/shared/zx-date-time-display/zx-date-time-display.component";
import { assignDateTimeDisplayInputsFactory } from "src/app/components/shared/zx-date-time-display/zx-date-time-display.table-adapter";
import { TimeZoneService, dateFormats } from "src/app/services/time-zone.service";
import { HttpParams } from "@angular/common/http";
interface State {
    page: number;
    pageSize: number;
}

const IS_SCTE35_COLORS_VISIBLE_LOCAL_STORAGE_KEY = "scte35.isScte35ColorsVisible";

@Component({
    selector: "app-scte",
    templateUrl: "./scte.component.html"
})
export class ScteComponent implements OnInit, OnDestroy {
    loading = true;
    scteLogs: SCTELog[] = [];
    isAdmin: boolean;

    scteFilter: SCTEFilter;
    showFromPicker: boolean;
    showToPicker: boolean;
    fromCounter = 0;
    toCounter = 0;
    fromDate: string | null;
    toDate: string | null;
    fromDateIso: string | null;
    toDateIso: string | null;

    constants = Constants;

    selectedResourceTags: Tag[] | null;
    selectedSources: Source[] | null;
    msgFilter: string | null;
    sourceIDs: number[];

    showFilter: boolean;
    autoPageSize: boolean;
    selectedPageSize: string | number;
    allowDownloadButton = false;

    tableExpanded: boolean;
    morePages = true;
    repotParameters: HttpParams;

    private scteLogsSubscription: Subscription;
    private isAdminSubscription: Subscription;
    private resizeSubscription: Subscription;

    private scteLogsBS$ = new BehaviorSubject<SCTELog[]>([]);

    private state: State = {
        page: 1,
        pageSize: 100
    };
    isScte35ColorsVisible = false;

    tagsControl: UntypedFormControl = new UntypedFormControl([]);
    dateFormat = "medium";

    @ViewChild("listPanel", { static: true }) listPanel: ElementRef;
    @ViewChild("listBottom", { static: true }) listBottom: ElementRef;

    private rs = inject(ResizeService);
    private ss = inject(ScteService);
    private userService = inject(UsersService);
    private ldms = inject(LogDetailModalService);
    private translate = inject(TranslateService);
    private titleService = inject(TitleService);
    private timeZoneService = inject(TimeZoneService);

    tableColumnsSchema: TableSchema<KeyMap<SCTELog>>[] = [
        {
            header: this.translate.instant("SOURCE"),
            columnDef: "source_name",
            width: 140,
            visible: true,
            sticky: 1,
            style: this.getCellStyle.bind(this),
            valueToExport: row => row.source_name
        },
        {
            header: this.translate.instant("DATE/TIME"),
            columnDef: "date",
            width: 220,
            visible: true,
            component: ZxDateTimeDisplayComponent,
            assignComponentsInputs: assignDateTimeDisplayInputsFactory<KeyMap<SCTELog>>(row => row.created_at),
            style: this.getCellStyle.bind(this),
            valueToExport: row =>
                this.timeZoneService.computeDateToTimeZoneReturnString(row.created_at, dateFormats.longDateTimeSeconds)
        },
        {
            header: this.translate.instant("PID"),
            columnDef: "pid",
            width: 80,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<SCTELog>>(
                row => row.pid.toString(),
                row => row.pid.toString(),
                () => true
            ),
            style: this.getCellStyle.bind(this),
            valueToExport: row => row.pid.toString()
        },
        {
            header: this.translate.instant("AUTO_RETURN"),
            columnDef: "auto_return",
            width: 100,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<SCTELog>>(
                row => (row.auto_return ? "Yes" : "No"),
                row => (row.auto_return ? "Yes" : "No"),
                () => true
            ),
            textValue: row => (row.auto_return ? "Yes" : "No"),
            valueToExport: row => (row.auto_return ? "Yes" : "No"),
            style: this.getCellStyle.bind(this)
        },
        {
            header: this.translate.instant("PRE_ROLL"),
            columnDef: "pre_roll",
            width: 100,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<SCTELog>>(
                row => (row.pre_roll ? String((row.pre_roll / 90000).toFixed(3)) : "-"),
                row => (row.pre_roll ? String((row.pre_roll / 90000).toFixed(3)) : "-"),
                () => true
            ),
            textValue: row => (row.pre_roll ? String((row.pre_roll / 90000).toFixed(3)) : "-"),
            valueToExport: row => (row.pre_roll ? String((row.pre_roll / 90000).toFixed(3)) : "-"),
            style: this.getCellStyle.bind(this)
        },
        {
            header: this.translate.instant("PTS"),
            columnDef: "pts_time",
            width: 100,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<SCTELog>>(
                row => (row.pts_time ? String(row.pts_time) : "-"),
                row => (row.pts_time ? String(row.pts_time) : "-"),
                () => true
            ),
            textValue: row => (row.pts_time ? String(row.pts_time) : "-"),
            valueToExport: row => (row.pts_time ? String(row.pts_time) : "-"),
            style: this.getCellStyle.bind(this)
        },
        {
            header: this.translate.instant("SEG_UPID_ASCII"),
            columnDef: "segmentation_upid_ascii",
            width: 100,
            visible: false,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<SCTELog>>(
                row => row.segmentation_upid_ascii ?? "-",
                row => row.segmentation_upid_ascii ?? "-",
                () => true
            ),
            textValue: row => row.segmentation_upid_ascii ?? "-",
            valueToExport: row => row.segmentation_upid_ascii ?? "-",
            style: this.getCellStyle.bind(this)
        },
        {
            header: this.translate.instant("SEG_UPID_HEX"),
            columnDef: "segmentation_upid_hex",
            width: 100,
            visible: false,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<SCTELog>>(
                row => row.segmentation_upid_hex ?? "-",
                row => row.segmentation_upid_hex ?? "-",
                () => true
            ),
            textValue: row => row.segmentation_upid_hex ?? "-",
            valueToExport: row => row.segmentation_upid_hex ?? "-",
            style: this.getCellStyle.bind(this)
        },
        {
            header: this.translate.instant("SEG_TYPE_HEX"),
            columnDef: "seg_type_code",
            width: 100,
            visible: false,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<SCTELog>>(
                row => (row.seg_type_code ? `0x${row.seg_type_code.toString(16)}` : ""),
                row => (row.seg_type_code ? `0x${row.seg_type_code.toString(16)}` : ""),
                () => true
            ),
            textValue: row => (row.seg_type_code ? `0x${row.seg_type_code.toString(16)}` : ""),
            valueToExport: row => (row.seg_type_code ? `0x${row.seg_type_code.toString(16)}` : ""),
            style: this.getCellStyle.bind(this)
        },
        {
            header: this.translate.instant("SEG_TYPE"),
            columnDef: "segmentation_type",
            width: 140,
            visible: false,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<SCTELog>>(
                row => row.seg_type ?? "-",
                row => row.seg_type ?? "-",
                () => true
            ),
            textValue: row => row.seg_type ?? "-",
            valueToExport: row => row.seg_type ?? "-",
            style: this.getCellStyle.bind(this)
        },
        {
            header: this.translate.instant("MESSAGE"),
            columnDef: "message",
            width: 200,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<SCTELog>>(
                row => row.message,
                row => row.message,
                () => true
            ),
            valueToExport: row => row.message,
            style: this.getCellStyle.bind(this)
        },
        {
            header: this.translate.instant("SCTE35"),
            columnDef: "scte35",
            visible: false,
            hideFromColumnChooser: true,
            valueToExport: row => row.scte_msg
        },
        {
            header: this.translate.instant("ACTIONS"),
            columnDef: "actions",
            width: 60,
            align: "right",
            visible: true,
            stickyToLast: true,
            component: ZxTableActionsComponent,
            assignComponentsInputs: assignGenericLinkTextInputsFactory([
                {
                    icon: "info-circle",
                    translateTitle: "DETAILS",
                    onClickHandler: (row: SCTELog) => this.logDetails(row),
                    isTextVisible: false
                }
            ]),
            style: this.getCellStyle.bind(this)
        }
    ];

    ngOnInit() {
        // Set Title
        this.titleService.setTitle("SCTE_35", "");

        // Setup
        this.scteLogs = [];
        this.loading = true;
        this.msgFilter = "";

        // Subscriptions
        this.isAdminSubscription = this.userService.isAdmin.subscribe(bool => {
            this.isAdmin = bool;
        });

        // Resize
        this.resizeSubscription = this.rs.getCurrentSize.subscribe(x => {
            if (x > 3) {
                this.showFilter = false;
            }
        });

        this.scteLogsSubscription = this.ss.scteLogs.subscribe(scteLogs => {
            this.scteLogs = scteLogs;
            if (this.scteLogs) {
                this.prepTableData();
                this.loading = false;
            }
        });

        // getLogs
        this.ss.getSCTELogs(this.prepFilter());

        const isScte35ColorsVisible = localStorage.getItem(IS_SCTE35_COLORS_VISIBLE_LOCAL_STORAGE_KEY);
        if (isScte35ColorsVisible && isScte35ColorsVisible === "true") {
            this.isScte35ColorsVisible = true;
        }

        this.updateDownloadButtonState();
    }

    ngOnDestroy() {
        this.resizeSubscription.unsubscribe();
        this.isAdminSubscription.unsubscribe();
        this.scteLogsSubscription.unsubscribe();
        this.ss.clearSCTELogs();
    }

    get scteLogs$() {
        return this.scteLogsBS$.asObservable();
    }
    get page() {
        return this.state.page;
    }
    set page(page: number) {
        this._set({ page });
    }
    get pageSize() {
        return this.state.pageSize;
    }
    set pageSize(pageSize: number) {
        this._set({ pageSize });
    }

    private _set(patch: Partial<State>) {
        Object.assign(this.state, patch);
        this.prepTableData();
    }

    // Prep Table Data
    private prepTableData() {
        this.scteLogs = this.scteLogs
            .sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime())
            .reverse();
        this.scteLogsBS$.next(this.scteLogs);
    }

    // Prep Filter
    prepFilter(overrides: Partial<SCTEFilter> = {}, useOffset = false): SCTEFilter {
        const lastScteLog = last(this.scteLogs);
        this.scteFilter = Object.assign(
            {
                pageSize: this.pageSize,
                offset: useOffset ? (!lastScteLog ? undefined : JSON.stringify(lastScteLog.searchAfter)) : undefined,
                fromDate: this.fromDate ? moment(this.fromDateIso) : null,
                toDate: this.toDate ? moment(this.toDateIso) : null,
                search: this.msgFilter,
                resourceTags: map(this.selectedResourceTags, "id"),
                source_id: map(this.selectedSources, "id")
            },
            overrides
        );

        return this.scteFilter;
    }

    // Clear Filter
    clearFilter() {
        this.fromDate = null;
        this.fromDateIso = null;
        this.toDate = null;
        this.toDateIso = null;
        this.msgFilter = null;
        this.selectedResourceTags = null;
        this.selectedSources = null;
    }

    // Reload Logs
    async reloadLogs() {
        this.loading = true;
        this.page = 0;
        await this.ss.refreshSCTELogs(this.prepFilter({ pageSize: 100 }));
        this.updateReportParameters();
        this.updateDownloadButtonState();
        this.loading = false;
        this.morePages = this.checkIfMorePages();
    }

    // Reset Logs
    async resetLogs() {
        this.loading = true;
        this.clearFilter();
        this.page = 0;
        await this.ss.refreshSCTELogs(this.prepFilter({ pageSize: 100 }));
        this.updateDownloadButtonState();
        this.loading = false;
        this.morePages = this.checkIfMorePages();
    }

    // Log Details
    async logDetails(log: SCTELog) {
        await this.ldms.logDetails(log);
    }

    // From
    toggleFromPicker() {
        this.showFromPicker = true;
    }

    closeFromPicker() {
        window.focus();
        this.fromCounter = 0;
        this.showFromPicker = false;
    }

    clickOutsideFromPicker() {
        this.fromCounter = this.fromCounter + 1;
        if (this.fromCounter > 1) {
            this.closeFromPicker();
        }
    }

    clearFromDate() {
        this.fromDate = null;
        this.fromDateIso = null;
    }

    fromDateChanged(event: Date) {
        if (event !== null) {
            const date = moment.parseZone(event);
            this.fromDateIso = date.toISOString();
            const format = this.timeZoneService.getDateTimeFormat(dateFormats.longDateTimeSeconds);
            this.fromDate = date.format(format);
        }
    }

    // To
    toggleToPicker() {
        this.showToPicker = true;
    }

    closeToPicker() {
        window.focus();
        this.toCounter = 0;
        this.showToPicker = false;
    }

    clickOutsideToPicker() {
        this.toCounter = this.toCounter + 1;
        if (this.toCounter > 1) {
            this.closeToPicker();
        }
    }

    clearToDate() {
        this.toDate = null;
        this.toDateIso = null;
    }

    toDateChanged(event: Date) {
        if (event !== null) {
            const date = moment.parseZone(event);
            this.toDateIso = date.toISOString();
            const format = this.timeZoneService.getDateTimeFormat(dateFormats.longDateTimeSeconds);
            this.toDate = date.format(format);
        }
    }

    // Prevent key events except delete and backspace
    onlyDelete(event: KeyboardEvent) {
        if (event.key !== "Backspace" && event.key !== "Delete") event.preventDefault();
    }

    async currentPageInfo(page: { pageSize: number; pageIndex: number }) {
        if (this.scteLogs.length) {
            this.pageSize = page.pageSize;
            if (this.pageSize * (page.pageIndex + 2) >= this.scteLogs.length) {
                this.loading = true;
                const pageSize = (this.scteLogs.length % this.pageSize) + this.pageSize;

                await this.ss.loadMoreSCTELogs(
                    this.prepFilter(
                        {
                            pageSize
                        },
                        true
                    )
                );

                this.morePages = this.checkIfMorePages();
                this.loading = false;
            }
        }
    }

    checkIfMorePages(): boolean {
        const prevCount = this.scteLogs.length;
        const pageSize = (this.scteLogs.length % this.pageSize) + this.pageSize;
        return this.scteLogs.length < prevCount + pageSize;
    }

    toggleLogsStyle() {
        this.isScte35ColorsVisible = !this.isScte35ColorsVisible;
        localStorage.setItem(IS_SCTE35_COLORS_VISIBLE_LOCAL_STORAGE_KEY, this.isScte35ColorsVisible.toString());
    }

    getCellStyle(scteLog: SCTELog) {
        return this.isScte35ColorsVisible
            ? scte35ColorsService.getStyle(scteLog.segmentation_type_id, scteLog.seg_type)
            : "";
    }

    updateDownloadButtonState() {
        this.allowDownloadButton = !!(this.scteLogs?.length > 0 && this.fromDate && this.toDate);
    }

    updateReportParameters() {
        const eventsReportParams = {
            fromDate: this.fromDate ? moment(this.fromDateIso) : undefined,
            toDate: this.toDate ? moment(this.toDateIso) : undefined,
            search: this.msgFilter || undefined,
            resource_tags: map(this.selectedResourceTags, "id"),
            source_id: map(this.selectedSources, "id")
        };

        const params = this.ss.getSCTEParameters(eventsReportParams).delete("pageSize");
        this.repotParameters = params;
    }
}
